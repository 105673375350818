import PageSectionTestimonialCarousel from './PageSectionTestimonialCarousel'
import PageSectionTestimonialCards from './PageSectionTestimonialCards'

const PageSectionTestimonials = ({ testimonials, disabled, successStoriesPath, displayAsSlider }) => {
  return displayAsSlider ? (
    <PageSectionTestimonialCarousel testimonials={testimonials} />
  ) : (
    <PageSectionTestimonialCards
      testimonials={testimonials}
      disabled={disabled}
      successStoriesPath={successStoriesPath}
    />
  )
}

export default PageSectionTestimonials
