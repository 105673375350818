import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import { useIntl } from '../utils/IntlContext'
import media from '../utils/media'
import HoverCard from './HoverCard'

const CardContainer = styled(HoverCard)`
  background-color: white;
  border-radius: var(--border-radius);
  padding: var(--spacing-md);
  box-shadow: var(--box-shadow-primary-small);
  position: relative;
  float: left;
  text-align: center;

  flex: 0 0 100%;

  ${media.sm`
    flex: 0 0 calc(50% - (var(--spacing-md) / 2));
  `}

  ${media.md`
    flex: 0 0 calc(33.33% - (2 * var(--spacing-md) / 3));
  `}

  a {
    display: inherit;
  }

  h3 {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 5px;
  }
`

const Quote = styled.p`
  margin-bottom: var(--spacing-md);
  min-height: 200px;
`

const Info = styled.div`
  a {
    color: var(--color-primary) !important;
  }

  span strong {
    color: var(--color-primary);
  }
`

const Avatar = styled(GatsbyImage)`
  border-radius: 50%;
  margin: 0 auto;
  max-width: 60px;
  max-height: 60px;
`

const TestimonialContainer = styled.div`
  align-items: stretch;
  display: flex;
  flex-wrap: wrap;
  padding: var(--spacing-md);
  justify-content: center;
  gap: var(--spacing-md);
`

const TestimonialCard = ({ quote, name, position, image, disabled, successStoriesPath }) => {
  const { t } = useIntl()

  return (
    <CardContainer
      to={!disabled && successStoriesPath}
      $disable={disabled}>
      <Quote>&quot;{quote}&quot;</Quote>
      <Info>
        <Avatar
          image={image}
          alt={name}
        />
        <StaticImage
          src="../assets/img/stars.png"
          alt="5 star rating for our resume maker"
          layout="fixed"
          width={104}
          style={{ margin: '16px auto 8px' }}
        />
        <h3>{name}</h3>
        <p>{position}</p>
        {!disabled && (
          <span>
            <strong>{t('see_more')}</strong>
          </span>
        )}
      </Info>
    </CardContainer>
  )
}

const PageSectionTestimonialCards = ({ testimonials, disabled, successStoriesPath }) => (
  <TestimonialContainer>
    {testimonials
      .filter((testimonial) => testimonial.review.review)
      .map((testimonial) => (
        <TestimonialCard
          key={testimonial.id}
          quote={testimonial.review.review}
          name={testimonial.name}
          position={testimonial.industry}
          image={getImage(testimonial.userPhoto.testimonialCard)}
          disabled={disabled}
          successStoriesPath={successStoriesPath}
        />
      ))}
  </TestimonialContainer>
)

export default PageSectionTestimonialCards
