import { getImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import TestimonialCarousel from './TestimonialCarousel'

const Carousel = styled(TestimonialCarousel)`
  margin: 0 35px;
`

const PageSectionTestimonialCarousel = ({ testimonials }) => {
  const slides = testimonials.map((testimonial) => {
    return {
      author: testimonial.name,
      quote: testimonial.review.review,
      photo: getImage(testimonial.userPhoto.testimonialCarousel),
    }
  })

  return <Carousel slides={slides} />
}

export default PageSectionTestimonialCarousel
