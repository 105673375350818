import styled, { css } from 'styled-components'
import Link from './Link'

const HoverCard = styled(Link)`
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow-primary-small);
  display: block;
  overflow: hidden;
  transition: all 250ms cubic-bezier(0.4, 0, 0.25, 1);

  ${({ $disable }) =>
    !$disable &&
    css`
      cursor: pointer;

      &:hover {
        box-shadow: var(--box-shadow-primary-medium-bottom-with-glow);
        transform: translateY(-4px);
      }
    `}
`

export default HoverCard
