import { GatsbyImage } from 'gatsby-plugin-image'
import { useEffect, useState } from 'react'
import styled from 'styled-components'

import { fadeIn, slideIn } from '../utils/GlobalStyles'
import media, { breakpoints } from '../utils/media'

const Container = styled.section`
  margin: 0 20px 120px;
  min-height: auto;
  position: relative;

  ${media.sm`
    margin-left: auto;
    margin-right: auto;
    min-height: 370px;
  `}
`

const Slides = styled.ol`
  list-style: none;
  margin: 0;
  padding-bottom: 35px;

  ${media.sm`
    padding-bottom: 0;
  `}
`

const Slide = styled.li`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  height: ${({ $active }) => ($active ? 'auto' : 0)};
  margin: 0;

  ${media.sm`
    flex-wrap: nowrap;
  `}
`

const Content = styled.div`
  order: 1;
  margin-top: 25px;
  max-width: 100%;

  ${media.sm`
    order: 0;
    margin-top: 0;
    margin-right: 25px;
    max-width: 55%;
  `}
`

const Quote = styled.p`
  animation:
    0.2s ${fadeIn} ease-out,
    0.5s ${slideIn} ease-out;
  font-size: 125%;
  font-weight: 500;
  line-height: 150%;
  text-align: left;
  display: ${({ $active }) => ($active ? 'block' : 'none')};

  ${media.md`
    font-size: 150%;
  `}
`

const Author = styled.p`
  animation:
    0.5s ${fadeIn} ease-out,
    0.5s ${slideIn} ease-out;
  font-size: 112.5%;
  font-weight: 500;
  text-align: left;
  display: ${({ $active }) => ($active ? 'block' : 'none')};
  margin-bottom: 40px;

  && {
    color: var(--color-primary);
  }

  ${media.sm`
    padding-bottom: 20px;
  `}
`

const Photo = styled.aside`
  animation: 0.3s ${fadeIn} ease-in;
  filter: grayscale(1);
  overflow-y: hidden;
  display: ${({ $active }) => ($active ? 'block' : 'none')};
  margin: 0 auto 50px;
  order: 0;
  width: 100%;

  ${media.sm`
    order: 1;
    margin: 5px 0 0;
    max-width: 280px;
  `}

  ${media.md`
    max-width: 360px;
  `}
`

const Navigation = styled.nav`
  position: absolute;
  bottom: 0;
  left: 0;
`

const Dot = styled.span`
  border-radius: 100%;
  cursor: pointer;
  transition: all 0.2s;
  display: inline-block;
  height: 9px;
  margin-right: 8px;
  width: 9px;

  & {
    background-color: ${({ $active }) => ($active ? 'var(--color-primary)' : 'var(--color-lighter-grey)')};
  }
`

const smallScreen = typeof window !== 'undefined' && window.innerWidth < breakpoints.md

const TestimonialCarousel = ({ slides, className }) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [intervalId, setIntervalId] = useState(null)

  const slidesCount = slides.length

  const setSlide = (index) => {
    setCurrentIndex(index)
    removeInterval()
  }

  const nextSlide = () => setCurrentIndex((currentIndex) => (currentIndex + 1) % slidesCount)

  const removeInterval = () => clearInterval(intervalId)

  const animateSlides = () => {
    if (smallScreen) {
      // disable slides animation on small screens as it makes the page jump
      // due to variable height content
      return
    }

    const id = setInterval(nextSlide, 6000)

    setIntervalId(id)

    return removeInterval
  }

  useEffect(animateSlides, [])

  return (
    <Container className={className}>
      <Slides>
        {slides.map((slide, index) => (
          <Slide
            $active={index === currentIndex}
            key={index}>
            <Content>
              <Quote $active={index === currentIndex}>“{slide.quote}”</Quote>
              <Author $active={index === currentIndex}>{slide.author}</Author>
            </Content>

            <Photo $active={index === currentIndex}>
              <GatsbyImage
                image={slide.photo}
                alt={slide.author}
              />
            </Photo>
          </Slide>
        ))}
      </Slides>

      <Navigation>
        {slides.map((_slide, index) => (
          <Dot
            $active={index === currentIndex}
            key={index}
            onClick={() => setSlide(index)}
          />
        ))}
      </Navigation>
    </Container>
  )
}

export default TestimonialCarousel
